import '../shared/sentry/init'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { Search } from '../../main/components/search'
import { withQueryClientContext } from '../shared/components/QueryClientContext'
import { isIpad, isMobile } from '../shared/isMobile'
import { ready } from '../shared/ready'
import { getSanitizedTicker } from '../shared/ticker-sanitizer'
import ScamNotice from './scam-notice'
import OldSearch, { SearchProps } from './search'
import Settings from './settings-dropdown'
import { Time } from './time'

window.FLibs = { getSanitizedTicker }

/**
 * The script below allows iPads in landscape mode to size the page naturally
 * instead of forcing 1024px viewport which results in a zoomed in view
 */
if (isIpad()) {
  const minViewport = 1024
  const viewportString = { fixed: `width=${minViewport}`, fluid: 'width=device-width, initial-scale=1.0' }

  const getViewportWidth = () => Math.round(window.innerWidth * (window.visualViewport?.scale || 1))
  const setViewport = (fixedViewport: boolean) =>
    document
      .querySelector('meta[name=viewport]')
      ?.setAttribute('content', fixedViewport ? viewportString.fixed : viewportString.fluid)

  let viewportWidth = getViewportWidth()

  setViewport(viewportWidth <= minViewport)

  window.addEventListener('resize', function () {
    const newViewportWidth = getViewportWidth()
    // iOS fires resize on overscroll
    if (viewportWidth === newViewportWidth) return
    viewportWidth = newViewportWidth
    setViewport(newViewportWidth <= minViewport)
  })
}

function renderAccountDropdown(element: Element | null) {
  if (!element) return

  const root = ReactDOM.createRoot(element)
  root.render(<Settings />)
}

/**
 * Display scam notice when language matches 'ru' or 'et'
 */
function renderScamNotice(element: Element | null) {
  const languages = navigator.languages instanceof Array ? navigator.languages : [navigator.language]
  const targetLanguages = ['ru', 'et']
  const isTargetLanguage = languages
    .map((language) => language.toLowerCase())
    .some((language) => targetLanguages.some((targetLanguage) => language.includes(targetLanguage)))

  if (!element || !isTargetLanguage) return

  const root = ReactDOM.createRoot(element)
  root.render(<ScamNotice />)
}

const hasEnabledRedesign = FinvizSettings.hasRedesignEnabled
let root: ReactDOM.Root
function renderSearch(props?: SearchProps, retries = 0) {
  const container = document.getElementById('search')

  if (!container) {
    if (retries < 10) setTimeout(() => renderSearch(props, retries + 1), 300)
    return
  }

  root = ReactDOM.createRoot(container)

  if (!hasEnabledRedesign) {
    root.render(<OldSearch {...props} />)
  } else {
    const SearchComponent = withQueryClientContext(Search)

    root.render(
      <SearchComponent
        defaultPlacement="bottom-start"
        theme="navigationBar"
        size="medium"
        placeholder="Search ticker, company or profile"
        autoFocus={props?.focus}
        isMatchInputAndBoxWidth
      />
    )
  }
}

let didInitializeWithFocus = false
let searchAutoFocus = false
ready(function () {
  if (!didInitializeWithFocus) renderSearch({ focus: searchAutoFocus })

  const timeContainer = document.getElementById('time')
  if (timeContainer) {
    const timeRoot = ReactDOM.createRoot(timeContainer)
    timeRoot.render(<Time className="w-full text-info text-white" />)
  }

  renderAccountDropdown(document.getElementById('account-dropdown'))
  renderScamNotice(document.getElementById('scam-notice'))
})

window['SearchFocus'] = function () {
  didInitializeWithFocus = true
  searchAutoFocus = !isMobile()
  renderSearch({ focus: searchAutoFocus })
}

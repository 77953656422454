import isPlainObject from 'lodash.isplainobject'
import { ComponentType, LazyExoticComponent, lazy } from 'react'
import { ShouldRevalidateFunction, ShouldRevalidateFunctionArgs } from 'react-router-dom'

import { getCookie } from '../app/shared/cookie'
import { parseInitData } from '../app/shared/utils'
import { CRYPTO_TICKERS } from './constants'
import { FeatureFlag, Instrument, SortDirectionValue, SortableValue } from './types'
import { RecordType } from './types'

/**
 * Function which wraps React.lazy and checks whether a chunk is preloaded
 * from async-manifest. If yes, kick in the promise resolve early so we can
 * instantly render the component
 */
export function lazyLoadComponent<Props>({
  chunkName,
  load,
}: {
  chunkName: string
  load: () => Promise<{ default: ComponentType<Props> }>
}): LazyExoticComponent<ComponentType<Props>> {
  // Resolve earlier if prefetch element is present
  const hasPreloadElement = document.querySelector(`[data-chunk-id=${chunkName}]`)

  if (hasPreloadElement) {
    const componentLoader = load()

    return lazy(() => componentLoader)
  }

  return lazy(load)
}

const isObject = (value: any): value is RecordType => isPlainObject(value)

/**
 * Parse text as JSON
 */
export function parseAsJSON<Shape>(value: unknown | Shape): Shape | undefined {
  if (isObject(value)) {
    return value as Shape
  }

  let parsedValue
  try {
    if (typeof value === 'string') {
      parsedValue = JSON.parse(value)
    }
  } catch {
    return
  }
  return parsedValue
}

/**
 * remove @ symbol from a ticker
 */
export function cleanTicker(ticker: string) {
  return ticker.startsWith('@') ? ticker.substring(1) : ticker
}

/**
 * Get instrument for a ticker.
 *
 * - If ticker doesn’t start with `@` - stock
 * - Otherwise
 *   - one of `CRYPTO_TICKERS` - crypto
 *   - has length of 6 - forex
 *   - none of above - future
 *
 * NOTE: counterpart in charts 'charts/app/utils/chart.js'
 */

export function getInstrumentForTicker(ticker: string): Instrument {
  if (!ticker?.startsWith('@')) return Instrument.Stock

  const cleanedTicker = cleanTicker(ticker)

  if (CRYPTO_TICKERS.includes(cleanedTicker.toUpperCase())) return Instrument.Crypto
  if (cleanedTicker.length === 6) return Instrument.Forex

  return Instrument.Futures
}

/**
 * Async load Resize observer polyfill when we need it
 */
let resizeObserverPolyfill: typeof window.ResizeObserver
export async function loadResizeObserverPolyfill() {
  if (typeof window.ResizeObserver === 'undefined') {
    const polyfill = await import('resize-observer-polyfill')
    resizeObserverPolyfill = polyfill.default
    window.ResizeObserver = resizeObserverPolyfill
    return resizeObserverPolyfill as typeof window.ResizeObserver
  }
}

export async function getIsBrave() {
  return (navigator.brave && (await navigator.brave.isBrave())) || false
}

export function deserializeFeatureFlags(): Partial<Record<FeatureFlag, boolean>> | undefined {
  try {
    const cookie = decodeURIComponent(getCookie('featureFlags'))
    const flags = cookie.split('_').map((value) => {
      const [key, val] = value.split(':')
      return [key, val === '1']
    })

    return Object.fromEntries(flags)
  } catch {
    return
  }
}

export function serializeFeatureFlags(flags: Partial<Record<FeatureFlag, boolean>>) {
  return Object.entries(flags)
    .map(([key, val]) => `${key}:${Number(val)}`)
    .join('_')
}

export enum ChildPosition {
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  center,
  topCenter,
  rightCenter,
  bottomCenter,
  leftCenter,
}

export function getFlexAlignClasses(position: ChildPosition) {
  return {
    'justify-start': [ChildPosition.topLeft, ChildPosition.bottomLeft, ChildPosition.leftCenter].includes(position),
    'justify-center': [ChildPosition.center, ChildPosition.topCenter, ChildPosition.bottomCenter].includes(position),
    'justify-end': [ChildPosition.topRight, ChildPosition.bottomRight, ChildPosition.rightCenter].includes(position),
    'items-start': [ChildPosition.topLeft, ChildPosition.topRight, ChildPosition.topCenter].includes(position),
    'items-center': [ChildPosition.center, ChildPosition.rightCenter, ChildPosition.leftCenter].includes(position),
    'items-end': [ChildPosition.bottomLeft, ChildPosition.bottomRight, ChildPosition.bottomCenter].includes(position),
  }
}

interface NumberFormatOptions {
  fractions: number
  multiply: number
  showPlusSign: boolean
  defaultValue: string
}

const intlCache: { [key: number]: Intl.NumberFormat } = {}
function getIntl(fractions: number) {
  if (!intlCache[fractions])
    intlCache[fractions] = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: fractions,
      maximumFractionDigits: fractions,
    })
  return intlCache[fractions]
}

export function shortFormatNumber(
  value: number | null | undefined,
  { fractions = 2, showPlusSign = false, defaultValue = '-', multiply = 1 }: Partial<NumberFormatOptions> = {}
) {
  let num = value
  if (num === undefined || num === null || !Number.isFinite(num)) return defaultValue

  num *= multiply

  let suffix = ''
  const absValue = Math.abs(num)
  if (absValue >= 1e9) {
    num /= 1e9
    suffix = 'B'
  } else if (absValue >= 1e6) {
    num /= 1e6
    suffix = 'M'
  } else if (absValue >= 1000) {
    num /= 1000
    suffix = 'K'
  }

  const formatted = getIntl(fractions).format(num)

  return (showPlusSign && num > 0 ? '+' : '') + formatted + suffix
}

export function formatNumber(
  value: number | null | undefined,
  { fractions = 2, showPlusSign = false, defaultValue = '-' }: Partial<NumberFormatOptions> = {}
) {
  if (value === undefined || value === null) return defaultValue

  const fixedValue = value.toFixed(fractions)
  const prefix = showPlusSign && value > 0 ? '+' : ''

  if (parseFloat(fixedValue) === 0 && value !== 0) {
    return <span title={value.toString()}>{prefix + fixedValue}</span>
  }

  const formatted = getIntl(fractions).format(value)

  return prefix + formatted
}

export interface SortByColumnOptions {
  /**
   * Change order of `null` values.
   * - `false` Force sort null as first (asc) and last (desc)
   * - `true` Always sort null as last regardless of order
   * @default false
   */
  sortNullAsLast?: boolean

  /**
   * When true, null values will not be treated as special values
   * @default false
   */
  ignoreNullForComparison?: boolean
}

export function sortByColumn<ItemType>(
  options: SortByColumnOptions & {
    a: ItemType
    b: ItemType
    direction: SortDirectionValue
    valueGetter?: (row: ItemType) => SortableValue
  }
) {
  const valueA = options.valueGetter?.(options.a)
  const valueB = options.valueGetter?.(options.b)
  // Compare as strings
  if (typeof valueA === 'string' && typeof valueB === 'string') return valueA.localeCompare(valueB) * options.direction

  // Always sort null last if enabled
  if (options.sortNullAsLast && valueA === null) return Infinity
  if (options.sortNullAsLast && valueB === null) return -Infinity

  // Sort null first/last depending on direction
  if (!options.ignoreNullForComparison && valueA === null) return -options.direction
  if (!options.ignoreNullForComparison && valueB === null) return options.direction

  // Compare as numbers
  return (Number(valueA) - Number(valueB)) * options.direction
}

export function moveItemInArray<ItemType>(arr: ItemType[], sourceIndex: number, destinationIndex: number) {
  // make sure sourceIndex and destinationIndex are inside of arr
  const from = Math.max(0, Math.min(sourceIndex, arr.length - 1))
  const to = Math.min(arr.length - 1, Math.max(destinationIndex, 0))

  const items = [...arr]
  const [removed] = items.splice(from, 1)
  items.splice(to, 0, removed)

  return items
}

export function randomUUID() {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (substring) => {
    const c = Number(substring)
    return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  })
}

export function getUuid() {
  return window.crypto?.randomUUID?.() ?? randomUUID()
}

/**
 * This fn creates a revalidator rule which always revalidates loader when the
 * specified keys change but ignores changes to other keys. It also allows
 * `revalidator.revalidate` to work even if the query didn’t
 */
export function revalidateOnQueryChange(keys: string[]): ShouldRevalidateFunction {
  return (args) => {
    const prevParams = args.currentUrl.searchParams
    const nextParams = args.nextUrl.searchParams
    const requiredChanged = keys.some((key) => prevParams.get(key) !== nextParams.get(key))
    const otherQueryKeys = [...prevParams.keys(), ...nextParams.keys()].filter(
      (key, index, arr) => !keys.includes(key) && arr.indexOf(key) === index
    )
    const otherChanged = otherQueryKeys.some((key) => prevParams.get(key) !== nextParams.get(key))

    if (!requiredChanged && otherChanged) {
      return false
    }

    return args.defaultShouldRevalidate
  }
}

/**
 * This fn does the same as `revalidateOnQueryChange`
 * except you need to provide `ShouldRevalidateFunctionArgs` manually
 */
export function shouldRevalidateOnQueryChange(args: ShouldRevalidateFunctionArgs, keys: string[]) {
  return revalidateOnQueryChange(keys)(args)
}

export function parseRouteInitData<DataType>({
  elementId = 'route-init-data',
  fallback,
  removeElement = true,
}: { elementId?: string; fallback?: DataType; removeElement?: boolean } = {}): DataType | null {
  const element = document.getElementById(elementId)
  let initialData = fallback

  if (!element) return fallback ?? null

  try {
    const parsedData = parseInitData<DataType>(elementId)
    if (removeElement) element.remove()

    if (parsedData) initialData = parsedData
  } catch {
    initialData = fallback
  }

  return initialData ?? null
}

/**
 * Used as a callback to autoFocusOnShow/autoFocusOnHide to make sure the page
 * doesn’t jump when focusing the element
 */
export function focusWithoutScroll(element: HTMLElement | null) {
  requestAnimationFrame(() => {
    element?.focus({ preventScroll: element.ariaHasPopup !== null })
  })

  return !element
}

export function blurWithoutScroll(element: HTMLElement | null) {
  element?.focus({ preventScroll: true })

  return !element
}

/**
 * Can be used as a callback for events if you just need to prevent the default action
 */
export function preventDefault(ev: React.MouseEvent) {
  ev.preventDefault()
}

export function getNavigationLinkUrl(
  url: URL,
  preservedKeys?: string[],
  removeQueryKeys?: string[],
  additionalQueryValues: Record<string, string | number> = {}
) {
  const newUrl = new URL(url)

  if (preservedKeys?.length) {
    for (const key of newUrl.searchParams.keys()) {
      if (!preservedKeys.includes(key)) newUrl.searchParams.delete(key)
    }
  }

  removeQueryKeys?.forEach((key) => {
    newUrl.searchParams.delete(key)
  })

  Object.entries(additionalQueryValues).forEach(([key, value]) => {
    newUrl.searchParams.set(key, value as string)
  })

  return newUrl
}

export function getElitePageLink(campaign?: string) {
  const url = '/elite.ashx'
  const query = new URLSearchParams('utm_source=finviz&utm_medium=banner')

  if (campaign) {
    query.set('utm_campaign', campaign)

    return `${url}?${query.toString()}`
  }

  return url
}

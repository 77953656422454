import * as Sentry from '@sentry/browser'

import { ThrowableError } from '../../../main/services/api'
import { ignoreStack, sentryOptions } from './constants'

if ('FinvizSentrySettings' in window && !('sentryDisabled' in window)) {
  Sentry.init({
    ...sentryOptions,
    beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
      const error = hint.originalException as Error

      // Track throwable errors as `info` if the status is 520+
      if (error instanceof ThrowableError && error.status >= 520) {
        // Completely ignore 524 errors
        if (error.status === 524) return null
        return { ...event, level: 'info' } satisfies Sentry.ErrorEvent
      }

      if (
        ignoreStack.some((str) => error?.stack?.includes(str)) ||
        (error?.stack?.includes('cmp2.js') && error.message.match(/(AbortError|NetworkError|aborted|network)/i))
      ) {
        return null
      }

      return event
    },
  })

  if (FinvizSentrySettings.id > 0) {
    Sentry.setUser({ id: String(FinvizSentrySettings.id) })
  }

  try {
    Sentry.setTags({
      dpr: window.devicePixelRatio,
      redesign: window.FinvizSettings?.hasRedesignEnabled,
      dark: window.FinvizSettings?.hasDarkTheme,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    })
  } catch {}
}

window['Sentry'] = {
  captureException: Sentry.captureException,
  captureMessage: Sentry.captureMessage,
  withScope: Sentry.withScope,
}
